/* src/components/Footer/Footer.css */

.footer {
    background: linear-gradient(to right, #1c1c1c, #2c2c2c);
    color: white;
    padding: 30px 0;
    text-align: center;
    position: relative;
    box-shadow: 0 -4px 15px rgba(0, 0, 0, 0.5);
}

.footer-sections {
    display: flex;
    justify-content: space-between;
    padding: 0 400px;
}

.info-section {
    max-width: 200px;
    text-align: left;
    margin-left: 10px;
}

.follow-section {
    max-width: 200px;
    text-align: center;
    margin-right: 10px;
}

.social-icons {
    display: flex;
}

.social-icons a {
    margin: 0 10px;
    color: white;
    font-size: 1.5rem;
    transition: transform 0.3s;
}

.social-icons a:hover {
    transform: scale(1.2);
}

.info-section ul {
    list-style-type: none;
    padding: 0;
}

.info-section li {
    margin: 5px 0;
}

.info-section a {
    color: white;
    text-decoration: none;
    transition: color 0.3s;
}

.info-section a:hover {
    color: #FFD700;
    text-decoration: underline;
}

.footer-bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
    padding: 0 20px;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
}

.footer-bottom p {
    margin: 5px 0;
}

.footer-bottom a {
    color: #FFD700;
    text-decoration: none;
    transition: color 0.3s;
}

.footer-bottom a:hover {
    color: white;
    text-decoration: underline;
}

.footer-bottom a.light-blue {
    color: #4169E1;
    text-decoration: none;
    transition: color 0.3s;
}

.footer-bottom a.light-blue:hover {
    color: #87CEEB;
    text-decoration: underline;
}

/* Media Queries for Footer Responsiveness */
@media (max-width: 824px) {
    .footer-sections {
        flex-direction: column;
        align-items: center;
        padding: 0 20px; /* Reduced padding */
    }
    .footer {
        padding: 20px 0;
    }
    .info-section, .follow-section {
        max-width: 100%;
        margin: 10px 0;
        text-align: center;
    }
}

@media (max-width: 425px) {
    .footer {
        padding: 15px 0;
    }
    .social-icons a {
        font-size: 1.2rem;
        margin: 0 5px;
    }
    .footer-bottom p {
        font-size: 0.9rem;
    }
}

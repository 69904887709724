/* src/components/Navbar/Navbar.css */

.navbar {
    background-color: #2c2c2c;
    color: white;
    padding: 20px 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 10;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
    border-bottom: 2px solid #FFD700;
    transition: background-color 0.3s ease;
}

.navbar:hover {
    background-color: #3a3a3a;
}

.navbar-brand {
    display: flex;
    align-items: center;
}

.navbar-logo {
    height: 50px;
    margin-right: 15px;
}

.navbar-title {
    color: white;
    text-decoration: none;
    font-size: 2rem;
    font-weight: bold;
    transition: color 0.3s ease;
}

.navbar-title:hover {
    color: #FFD700;
}

.announcement-banner {
    background-color: #FF9800;
    padding: 5px 20px;
    margin-right: 105px;
    margin-bottom: 10px;
    border-radius: 5px;
}

.navbar-links {
    display: flex;
    gap: 5px;
}

.navbar-links a {
    color: white;
    text-decoration: none;
    padding: 10px 15px;
    border-radius: 5px;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.navbar-links a:hover {
    background-color: #FFD700;
    color: #2c2c2c;
}

.navbar-links a.active-link {
    color: #FFD700;
}

.active-link {
    font-weight: bold;
}

.announcement-text {
    font-weight: bold;
    color: #FFD700;
    font-size: 1.1rem;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.7);
    text-align: center;
}

/* Media Queries for Navbar Responsiveness */
@media (max-width: 824px) {
    .navbar {
        padding: 15px 20px;
    }
    .navbar-title {
        font-size: 1.5rem;
    }
    .navbar-logo {
        height: 40px;
    }
    .navbar-links a {
        padding: 8px 10px;
    }
    .announcement-banner {
        margin-right: 0;
        padding: 5px 10px;
    }
}

@media (max-width: 425px) {
    .navbar {
        flex-direction: column;
        align-items: flex-start;
        padding: 10px 15px;
    }
    .navbar-title {
        font-size: 1.2rem;
    }
    .navbar-links {
        flex-direction: column;
        width: 100%;
        gap: 2px;
    }
    .navbar-links a {
        padding: 8px;
        width: 100%;
        text-align: center;
    }
}

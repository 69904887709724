/* Ensure the body occupies the full height and prevents overflow */
body {
    margin: 0; /* Remove default margin */
    height: 100vh; /* Set body height to full viewport height */
    overflow: hidden; /* Prevent scrolling */
}

/* Leaderboard Container */
.leaderboard-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start; /* Align items to start of the container */
    height: 100%; /* Make container fill the full height */
    background: linear-gradient(135deg, #5a4b1c 0%, #3b2f12 40%, #e0c34b 100%);
    padding: 25px;
    box-shadow: 0 0 50px rgba(255, 215, 0, 0.8), 0 0 30px rgba(0, 0, 0, 0.5), 0 8px 20px rgba(0, 0, 0, 0.6);
    color: #f3e3b8;
    text-shadow: 2px 2px 0px rgba(255, 215, 0, 1), 1px 1px 0px rgba(0, 0, 0, 0.5);
    overflow-x: hidden; /* Prevent horizontal scrolling */
    width: 100%;
    max-width: 100vw;
}

/* Specific adjustments for mobile views */
@media (max-width: 824px) {
    .leaderboard-container {
        padding: 15px;
        overflow-x: hidden; /* Enforce no overflow */
    }
}

@media (max-width: 600px) {
    .leaderboard-container {
        padding: 10px;
        overflow-x: hidden; /* Enforce no overflow */
    }
}

@media (max-width: 425px) {
    .leaderboard-container {
        padding: 5px;
        overflow-x: hidden; /* Enforce no overflow */
    }
}



/* Countdown Timer */
.timer {
    margin-bottom: 0px;
    font-size: 1.5rem;
    font-weight: bold;
    color: #ff4444;
    background: linear-gradient(to right, #ff0000, #ff7f7f);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
}

/* Podium Section */
.podium {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    gap: 20px;
    margin-top: 20px;
}

.podium-spot {
    position: relative;
    width: 180px; /* Increase size */
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    background: rgba(255, 255, 255, 0.1); /* Transparent background */
    transition: transform 0.3s;
}

.podium-avatar {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin-bottom: 8px;
}

.podium-username {
    font-size: 1.2rem;
    font-weight: bold;
    margin: 8px 0;
    color: #ffffff;
    text-shadow: none;
    overflow: hidden;            /* Prevent text overflow */
    text-overflow: ellipsis;     /* Show ellipsis (...) for overflowing text */
    white-space: nowrap;         /* Prevent the text from wrapping to a new line */
    width: 100%;                 /* Ensure the width matches the container */
    display: inline-block;       /* Ensure the ellipsis works within the container */
    box-sizing: border-box;      /* Ensure padding doesn’t affect the width */
}


.podium-wagered-container {
    background: rgba(255, 255, 255, 0.1);
    padding: 8px;
    border-radius: 5px;
    margin-bottom: 8px;
}

.podium-wagered {
    font-size: 1rem;
    color: #333; /* Standard color */
    text-shadow: none; /* Remove shadow */
}

.podium-reward {
    font-size: 1rem; /* Slightly increase font size */
    font-weight: bold; /* Make text bold */
    color: #35ff02; /* Green color for reward text */
    text-shadow: 0 0 5px rgba(53, 255, 2, 0.7), 0 0 10px rgba(53, 255, 2, 0.5); /* Add a glow effect */
    transition: transform 0.3s; /* Add a transition for scaling effect */
}

.spot-1 {
    transform: translateY(-30px); /* Adjust for higher elevation */
    background: linear-gradient(145deg, rgba(255, 215, 0, 0.8), rgba(255, 223, 0, 0.5)); /* Gold */
}

/* 2nd Place - Slightly lower than 1st */
.spot-2 {
    transform: translateY(-10px); /* Slightly elevated */
    background: linear-gradient(145deg, rgba(192, 192, 192, 0.8), rgba(220, 220, 220, 0.5)); /* Silver */
}

/* 3rd Place - Base level */
.spot-3 {
    transform: translateY(-10px); /* Base level */
    background: linear-gradient(145deg, rgba(205, 127, 50, 0.8), rgba(210, 140, 60, 0.5)); /* Bronze */
}

.podium-spot:hover {
    transform: scale(1.05); /* Scale up */
}

/* First place hover effect */
.spot-1:hover {
    transform: scale(1.05) translateY(-40px); /* Hovering upwards for first place */
}

/* Second place hover effect */
.spot-2:hover {
    transform: scale(1.05) translateY(-20px); /* Hovering upwards for second place */
}

/* Third place hover effect */
.spot-3:hover {
    transform: scale(1.05) translateY(-20px); /* Hovering upwards for third place */
}

@media (max-width: 600px) {
    .podium {
        flex-direction: column;
        align-items: center;
    }
    .spot-1, .spot-2, .spot-3 {
        transform: none;
    }
}

/* Leaderboard Table */
.leaderboard-table {
    width: 100%;
    table-layout: auto; /* Or fixed, based on your preference */
    max-width: 800px;
    margin-top: 20px;
}

.leaderboard-table table {
    table-layout: fixed; /* Ensures columns have fixed widths */
    width: 200%;
    border-collapse: collapse;
}

.leaderboard-table th, .leaderboard-table td {
    padding: 10px; /* Padding for cells */
    border: 1px solid transparent; /* Light border */
    text-align: center; /* Center align text */
    font-weight: normal; /* Normal weight */
    font-size: 1rem; /* Standard font size */
    color: #333; /* Standard color */
    text-shadow: none; /* Remove shadow */
}

.leaderboard-tablese th, 
.leaderboard-tablese td {
    padding: 10px; /* Padding for cells */
    border: 1px solid transparent; /* Light border */
    text-align: center; /* Center align text */
    font-weight: normal; /* Normal weight */
    font-size: 1rem; /* Standard font size */
    color: #333; /* Standard color for table data */
    text-shadow: none; /* Remove shadow */
}

.leaderboard-table th {
    background-color: transparent; /* Light background for header */
    font-weight: bold; /* Bold header */
    color: #f3e3b8; /* Change this color to your desired header color */
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.7); /* Optional: add shadow for header */
}

.leaderboard-table tr:nth-child(even) {
    background-color: #f9f9f9; /* Zebra striping for rows */
}

/* Logo Container */
.logo-containerend {
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
    transform: translateY(-40px) scale(0.75); /* Move up by 10px and scale down */
}

.logo-item {
    flex: 0.8;
    position: relative;
    background: rgba(255, 255, 255, 0.06);
    border-radius: 5px;
    padding: 4px;
    margin-right: 0;
}

.logo-item img {
    width: 70%;
    height: auto;
    margin: 0 auto;
    transition: transform 0.3s ease;
}

.logo-item img:hover {
    transform: translateY(6px) scale(1.2);
}

/* Gradient lines for each logo-item */
.logo-item:nth-child(1)::after {
    background: linear-gradient(to right, #d3d3d3, transparent);
}

.logo-item:nth-child(2)::after {
    background: linear-gradient(to right, #ff007f, transparent);
}

.logo-item:nth-child(3)::after {
    background: linear-gradient(to right, #4169e1, #ffd700);
}

.logo-item:nth-child(4)::after {
    background: linear-gradient(to right, #9966cc, transparent);
}

.logo-item:nth-child(5)::after {
    background: linear-gradient(to right, transparent, #ffd700);
}

.logo-item::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 5px;
    border-radius: 3px;
}

/* Highlighted Arrow for 3rd Logo */
.logo-item:nth-child(3) .arrow {
    position: absolute;
    bottom: -8px;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid #ffd700;
}

/* Gradient Title and Text */
.gradient-texters {
    font-size: 1.2rem;
    color: #f3e3b8; /* Light gold */
    font-weight: bold;
    text-align: center;
    margin-bottom: 16px;
    background: none;
    -webkit-background-clip: initial;
    -webkit-text-fill-color: initial;
    text-shadow: none;
    transform: translateY(-25px); /* Move up by 10px and scale down */
    display: inline-block;
}

.leaderboard-title {
    font-size: 3rem;
    color: #ffd726; /* Solid gold color for a classic look */
    margin: 20px 0;
    font-weight: bold;
    text-align: center;
    background: none; /* Ensure no background is applied */
    -webkit-background-clip: initial;
    -webkit-text-fill-color: initial;
    text-shadow: none; /* Remove any potential text shadow */
}

.leaderboard-table thead th {
    padding: 10px 0; /* Set padding to top/bottom, left/right will be adjusted individually */
    vertical-align: middle; /* Ensure vertical alignment is consistent */
    background-color: transparent; /* Background for header */
    color: #f3e3b8; /* Color for header text */
    text-align: center; /* Default center alignment */
}

.leaderboard-table tbody tr {
    display: flex; /* Use flex to align cells horizontally */
    justify-content: space-between; /* Space out items evenly within the row */
    width: 200%; /* Full width for each row */
    padding: 10px 0; /* Adjust vertical padding for height */
    margin: 5px 0; /* Adjust space between rows */
    border-radius: 8px; /* Rounded corners */
    border: 1px solid transparent; /* Border for the outline */
    background: linear-gradient(135deg, #3b2f12, #1f1b12); /* Gradient background */
    transition: background 0.3s ease, transform 0.3s ease; /* Transition for smooth hover effect */
}

.leaderboard-table tbody tr:hover {
    background: linear-gradient(135deg, #d0b43c, #3b2f12); /* Change background on hover */
    transform: scale(1.02); /* Slightly scale up on hover */
    box-shadow: 0 0 10px rgba(255, 215, 0, 0.5); /* Glow effect on hover */
}

.leaderboard-table td {
    flex: 1; /* Allow cells to grow and fill available space */
    padding: 10px 15px; /* Adjusted padding for cells */
    text-align: center; /* Center align text */
    color: #f3e3b8; /* Standard color for table data */
    font-weight: normal; /* Normal weight for table data */
    font-size: 1rem; /* Standard font size */
}

.leaderboard-table .header-rank {
    padding-left: 82px !important; /* Forces padding to override other styles */
    text-align: left !important;
}

.leaderboard-table .header-username {
    transform: translateX(-134px); /* Move the text to the left */
    padding-left: -90px !important;
    text-align: left !important;
}

.leaderboard-table .header-wagered {
    transform: translateX(-579px); /* Move the text to the left */
    padding-right: 80px !important;
    text-align: right !important;
}

.leaderboard-table .header-reward {
    transform: translateX(-747.5px); /* Move the text to the left */
    padding-right: 120px !important;
    text-align: right !important;
}

.leaderboard-avatar {
    width: 40px; /* Set the width */
    height: 40px; /* Set the height */
    border-radius: 50%; /* Makes the avatar round */
    margin-right: 10px; /* Space between avatar and username */
}

.username-cell {
    display: flex; /* Use flexbox for alignment */
    align-items: center; /* Center vertically */
}

.image-overlaydd {
    position: absolute;
    top: 450px;
    right: 0; /* Align to the right edge */
    width: 275px;
    height: 110vh;
    background-image: url('../../public/assets/overlaylbjordan.png');
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 5;
    animation: rover 6s ease-in-out infinite;
}

/* Overlay on the left */
.image-overlayddd {
    position: absolute;
    top: 530px;
    left: 0; /* Align to the left edge */
    width: 275px;
    height: 100vh;
    background-image: url('../../public/assets/overlaylbjordans.png');
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 5;
    animation: rover 6s ease-in-out infinite;
}

/* Media Queries for Responsive Overlay Images */

/* Extra Large Screens */
@media (max-width: 2560px) {
    .image-overlaydd {
        width: 505px;
        height: 90vh;
        top: 400px; /* Adjust position for extra large screens */
        right: 0px; /* Ensure alignment */
    }
    .image-overlayddd {
        width: 490px;
        height: 79vh;
        top: 450px; /* Adjust position */
        left: 40px; /* Ensure alignment */
    }
}
/* Extra Large Screens */
@media (max-width: 1440px) {
    .image-overlaydd {
        width: 270px;
        height: 90vh;
        top: 180px; /* Adjust position for extra large screens */
        right: 0px; /* Ensure alignment */
    }
    .image-overlayddd {
        width: 640px;
        height: 79vh;
        top: 300px; /* Adjust position */
        left: -350px; /* Ensure alignment */
    }
}
/* Large Screens */
@media (max-width: 1024px) {
    .image-overlaydd {
        width: 200px;
        height: 90vh;
        top: 130px; /* Adjust position for larger screens */
        right: 0px; /* Ensure alignment */
    }
    .image-overlayddd {
        width: 600px;
        height: 79vh;
        top: 200px; /* Adjust position */
        left: -350px; /* Ensure alignment */
    }
}

/* Medium Screens */
@media (max-width: 824px) {
    .image-overlaydd {
        width: 140px;
        height: 80vh;
        top: 300px; /* Adjust position for medium screens */
        right: 0px; /* Ensure alignment */
    }
    .image-overlayddd {
        width: 370px;
        height: 80vh;
        top: 200px; /* Adjust position */
        left: -250px; /* Ensure alignment */
    }
}

/* Small Screens */
@media (max-width: 600px) {
    .image-overlaydd {
        width: 150px;
        height: 70vh;
        top: 200px; /* Adjust position for smaller screens */
        right: 0;
    }
    .image-overlayddd {
        width: 150px;
        height: 70vh;
        top: 250px;
        left: 0;
    }
}

/* Smallest Screens */
@media (max-width: 425px) {
    .image-overlaydd {
        width: 120px;
        height: 60vh;
        top: 500px;
        right: 0; /* Align to right edge */
    }
    .image-overlayddd {
        width: 300px;
        height: 60vh;
        top: 520px;
        left: -160px; /* Align to left edge */
    }
}
Explanation of Adjustments
/* Default styling */
.signupButton {
    background: linear-gradient(to right, gold, orange);
    transform: translateY(-45px);
    border: none;
    border-radius: 6px;
    padding: 7px 250px;
    font-size: 16px;
    font-weight: bold;
    color: #000;
    margin: 0 auto;
    display: block;
    text-decoration: none;
    transition: transform 0.3s ease, background 0.3s ease, color 0.3s ease;
    position: relative;
    z-index: 10;
  }
  
  .signupButton:hover {
    transform: translateY(-45px) scale(1.05);
    background: orange;
    color: #fff;
  }
  
  /* Media Queries */
  @media (max-width: 824px) {
    .signupButton {
      padding: 7px 200px;
      font-size: 15px;
    }
  }
  
  @media (max-width: 600px) {
    .signupButton {
      padding: 7px 150px;
      font-size: 14px;
    }
  }
  
  @media (max-width: 425px) {
    .signupButton {
      padding: 7px 100px;
      font-size: 13px;
    }
  }
/* Leaderboard.module.css */

.signupButton {
    background: linear-gradient(to right, gold, orange);
    transform: translateY(-45px);
    border: none;
    border-radius: 6px;
    padding: 7px 250px;
    font-size: 16px;
    font-weight: bold;
    color: #000;
    margin: 0 auto;
    display: block;
    text-decoration: none;
    transition: transform 0.3s ease, background 0.3s ease, color 0.3s ease;
    position: relative;
    z-index: 10;
  }
  
  .signupButton:hover {
    transform: translateY(-45px) scale(1.05);
    background: orange;
    color: #fff;
  }
  
  /* Media Queries */
  @media (max-width: 824px) {
    .signupButton {
      padding: 7px 200px;
      font-size: 15px;
    }
  }
  
  @media (max-width: 600px) {
    .signupButton {
      padding: 7px 150px;
      font-size: 14px;
    }
  }
  
  @media (max-width: 425px) {
    .signupButton {
      padding: 7px 100px;
      font-size: 13px;
    }
  }
  
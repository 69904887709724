/* src/Preloader.css */

.preloader {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed; /* Change to fixed */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, #000000, #d9d9d9); /* Linear gradient from light grey to darker grey */
    overflow: hidden; /* Prevent bubbles from overflowing */
    z-index: 9999; /* Ensure it stays on top */
}

.logo-containerpreloader {
    width: 160px; /* Adjust size for the container */
    height: 160px; /* Adjust size for the container */
    border-radius: 50%; /* Make it circular */
    border: 5px solid gold; /* Outline in gold */
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden; /* Ensures logo fits within the circle */
    animation: bounceR 1s infinite; /* Add bounce effect */
}

.logoER {
    width: 220px; /* Adjust the size of the logo */
    height: auto; /* Maintain aspect ratio */
}

.bubble {
    position: absolute;
    bottom: -50px; /* Start below the view */
    background-color: rgba(255, 255, 255, 0.8); /* Bubble color */
    border-radius: 50%; /* Make it circular */
    opacity: 0.7; /* Slightly transparent */
    animation: riseR 3s infinite; /* Bubble rise animation */
}

@keyframes riseR {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(-100vh); /* Move the bubble upwards */
        opacity: 0; /* Fade out */
    }
}

@keyframes bounceR {
    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-10px); /* Adjust bounce height */
    }
    60% {
        transform: translateY(-5px); /* Adjust bounce height */
    }
}


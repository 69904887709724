/* src/global.css */

/* Reset some default styles */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: 'Montserrat', sans-serif;
    line-height: 1.6;
    background-color: #f8f9fa; /* Light background color */
    color: #333; /* Default text color */

    /* Allow scrolling */
    overflow: auto; /* Allows scrolling */
}


/* For Firefox */
html {
    scrollbar-width: none; /* Hides scrollbar in Firefox */
}

/* For Webkit-based browsers */
body::-webkit-scrollbar {
    display: none; /* Hides the scrollbar */
}

h1, h2, h3, h4, h5, h6 {
    margin-bottom: 10px; /* Space below headings */
}

a {
    text-decoration: none; /* Remove underline from links */
    color: inherit; /* Use inherited color for links */
}

/* Add some spacing for the main content */
main {
    padding: 20px;
}

/* Button styles */
button {
    cursor: pointer; /* Change cursor to pointer for buttons */
}

/* Home page style */
.home-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start; /* Change from center to flex-start to push content to the top */
    height: auto; /* Allow the height to grow with content */
    min-height: 100vh; /* Ensure it takes at least the full viewport height */
    text-align: center;
    padding: 20px;
    color: #ffffff;
    overflow: hidden; /* Hide overflow for home container */
    position: relative;
    background-color: #0e0e0e; /* Darker grey background */
    z-index: 1;
}

/* Gradient overlay */
.home-container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.055), rgba(255, 255, 255, 0.479));
    filter: blur(50px); /* Soft blur for the glow effect */
    z-index: 0; /* Send the gradient behind other content */
}

h1 {
    font-size: 3rem;
    margin-bottom: 20px;
    color: #ffffff;
    text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.6); /* Soft shadow for depth */
    position: relative; /* To make sure text appears above the gradient */
    z-index: 1; /* Bring the text above the gradient */
}

h2 {
    margin: 20px 0; /* Margin for spacing */
}

p {
    font-size: 1.2rem;
    margin: 10px 0;
    color: #d0d7dc; /* Lighter grey for text */
    max-width: 600px;
    line-height: 1.5;
    position: relative; /* Ensure this text appears above the gradient */
    z-index: 1;
}

.view-leaderboard {
    margin-top: 25px;
    padding: 12px 24px;
    font-size: 1.1rem;
    color: #ffffff;
    background-color: rgba(15, 82, 186, 0.8); /* Sapphire with transparency */
    border-radius: 8px;
    text-decoration: none;
    transition: background-color 0.3s ease, transform 0.3s ease;
    position: relative; /* Ensure button appears above the gradient */
    z-index: 1;
}

.view-leaderboard:hover {
    background-color: rgba(15, 82, 186, 1);
    transform: scale(1.05);
}

.my-leaderboard {
    text-align: center; /* Center the text */
    transform: translateY(20px); /* Move down by 20 pixels */
    transform-origin: top; /* Ensure it transforms from the top */
    transition: transform 0.3s ease; /* Optional: add transition for smooth effect */
}

.leaderboard-logo {
    height: 100px; /* Adjust logo height as needed */
    margin: 10px 0; /* Space between the logo and the coin amount */
}

.gradient-text {
    background: linear-gradient(135deg, #f9c74f, #f6ab02, #f9c74f, #f9e27a); /* A smooth gold gradient */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 2em; /* Adjust size as needed */
    margin: 20px 0; /* Adjust margin as needed */
    font-weight: bolder;
    text-align: center; /* Center the text */
    animation: followTap 1.5s ease-in-out infinite;
    position: relative; /* Ensure it can be transformed properly */
    top: 20px; /* Move it down by 20px */
}

@keyframes followTap {
    0%, 100% {
        transform: scale(1) translateY(0); /* No vertical movement */
    }
    50% {
        transform: scale(1.1) translateY(0); /* Still no vertical movement */
    }
}


.logo-container {
    background: linear-gradient(135deg, rgba(255, 215, 0, 0.4), rgba(255, 223, 186, 0.1)); /* Gold gradient with high opacity */
    padding: 20px; /* Add some padding around the logo */
    border-radius: 15px; /* Rounded corners */
    display: inline-block; /* Allows the container to size around the image */
    margin: 20px 0; /* Space around the container */
}

.logo-containerrust {
    background: linear-gradient(135deg, rgba(255, 0, 170, 0.4), rgba(255, 223, 186, 0.1)); /* Gold gradient with high opacity */
    padding: 20px; /* Add some padding around the logo */
    border-radius: 15px; /* Rounded corners */
    display: inline-block; /* Allows the container to size around the image */
    margin: 20px 0; /* Space around the container */
}

.logo-containerrush {
    background: linear-gradient(135deg, rgba(255, 153, 0, 0.932), rgba(255, 223, 186, 0.1)); /* Gold gradient with high opacity */
    padding: 20px; /* Add some padding around the logo */
    border-radius: 15px; /* Rounded corners */
    display: inline-block; /* Allows the container to size around the image */
    margin: 20px 0; /* Space around the container */
}

.coin-amount {
    display: flex; /* Align items in a row */
    align-items: center; /* Center vertically */
    margin: -9px 55px; /* Add some margin around the text */
    transform: translateY(-20px); /* Move up by 20 pixels */
    width: 160px; /* Fixed width for the container */
    overflow: hidden; /* Hide any overflow content */
    white-space: nowrap; /* Prevent text from wrapping */
    text-overflow: ellipsis; /* Show ellipsis for overflowed text */
}

.coin-amountrust {
    display: flex; /* Align items in a row */
    align-items: center; /* Center vertically */
    margin: -9px 55px; /* Add some margin around the text */
    transform: translateY(-20px) translateX(25px); /* Move up by 20 pixels and right by 30 pixels */
    width: 160px; /* Fixed width for the container */
    overflow: hidden; /* Hide any overflow content */
    white-space: nowrap; /* Prevent text from wrapping */
    text-overflow: ellipsis; /* Show ellipsis for overflowed text */
}

.coin-amountbig {
    display: flex; /* Align items in a row */
    align-items: center; /* Center vertically */
    margin: -9px 55px; /* Add some margin around the text */
    transform: translateY(-20px) translateX(25px); /* Move up by 20 pixels and right by 30 pixels */
    width: 160px; /* Fixed width for the container */
    overflow: hidden; /* Hide any overflow content */
    white-space: nowrap; /* Prevent text from wrapping */
    text-overflow: ellipsis; /* Show ellipsis for overflowed text */
}


.coin-icon {
    width: 30px; /* Set width for the coin icon */
    height: auto; /* Maintain aspect ratio */
    margin-right: 10px; /* Space between the icon and the amount */
}

.coin-iconrust {
    width: 30px; /* Set width for the coin icon */
    height: auto; /* Maintain aspect ratio */
    margin-right: 10px; /* Space between the icon and the amount */
}

.amount {
    font-size: 1.5rem; /* Adjust font size for the amount */
    font-weight: bold; /* Make the amount bold */
    color: #efbc64; /* Gold color for the text */
}

.amountrust {
    font-size: 1.5rem; /* Adjust font size for the amount */
    font-weight: bold; /* Make the amount bold */
    color: #ffffff; /* Gold color for the text */
}

.amountrush {
    font-size: 1.5rem; /* Adjust font size for the amount */
    font-weight: bold; /* Make the amount bold */
    color: #ffffff; /* Gold color for the text */
}

.particles-js {
    position: absolute; /* Maintain absolute positioning */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1; /* Higher than navbar/footer */
}

.floating-particle {
    position: absolute;
    width: 30px; /* Adjust size as needed */
    height: 30px; /* Adjust size as needed */
    background-image: url('../public/assets/snowflakes.png'); /* Path to your snowflake image */
    background-size: cover; /* Ensures the image covers the div */
    opacity: 0.8; /* Adjust opacity as needed */
    pointer-events: none; /* Prevents mouse events on particles */
    animation: float 10s linear infinite; /* Adjust duration for desired effect */
}

@keyframes float {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(100vh); /* Move down the screen */
    }
}

.divider-container {
    display: flex;
    align-items: center; /* Center vertically */
    justify-content: center; /* Center content */
    margin: 20px 0; /* Margin around the container */
}

.logo-container {
    transition: transform 0.3s, background-color 0.3s;
}

.logo-container:hover {
    transform: scale(1.05);
    background-color: transparent; /* Adjust as desired */
}


.divider {
    width: 2px; /* Adjust thickness of the divider */
    height: 80px; /* Adjust height of the divider */
    background-color: #d0d7dc; /* Color of the divider */
    margin: 0 20px; /* Space between the dividers and the containers */
}

.coming-soon-overlay {
    position: absolute; /* Position overlay absolutely */
    top: 50%; /* Center vertically */
    left: 50%; /* Center horizontally */
    transform: translate(-50%, -50%); /* Center the text */
    background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent black background */
    color: white; /* Text color */
    font-size: 1.5rem; /* Font size */
    padding: 10px 20px; /* Padding around the text */
    border-radius: 8px; /* Rounded corners */
    z-index: 2; /* Ensure overlay is above other content */
}

/* Ensure parent containers are positioned relatively */
.logo-containerrust,
.logo-containerrush {
    position: relative; /* Set position for child overlay */
    display: inline-block; /* Allows the overlay to be positioned correctly */
}

/*follow me / social media card section*/

.follow-me-section {
    text-align: center;
  }
  
  .social-cards {
    display: flex;
    justify-content: center;
    gap: 30px; /* Increased gap for spacing between larger cards */
    margin-top: 20px;
  }
  
  .social-card {
    width: 200px; /* Increased width for larger card size */
    background-color: rgba(155, 139, 139, 0.8); /* Semi-transparent background */
    border-radius: 15px; /* Slightly more rounded corners for larger cards */
    padding: 25px;
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    position: relative;
    box-shadow: 0 0 0 transparent; /* No outline by default */
  }
  
  .social-card:hover {
    transform: scale(1.08); /* Slightly larger scale on hover */
    box-shadow: 0 0 8px 3px rgba(0, 123, 255, 0.5); /* More pronounced outline on hover */
  }
  
  .social-icon-circle {
    width: 80px; /* Increased size of the white circle */
    height: 80px;
    background-color: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto 15px;
  }
  
  .social-icon {
    width: 40px; /* Larger icon size */
    height: 40px;
  }
  
  h4 {
    font-size: 1.3rem; /* Increased font size for the title */
    margin: 12px 0 8px;
  }
  
  p {
    font-size: 1rem; /* Slightly larger font size for description */
    color: #666;
    margin-bottom: 12px;
  }
  
  .follow-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px; /* Increased button padding for larger appearance */
    border-radius: 6px;
    cursor: pointer;
    font-size: 1rem;
    margin-top: 10px;
    transition: background-color 0.3s ease;
  }
  
  .follow-button:hover {
    background-color: #0056b3;
  }
  

.gradient-follow {
    background: linear-gradient(45deg, #ffffff, #000000, #ffffff);
    -webkit-background-clip: text;
    color: transparent;
    font-size: 2em;
    font-weight: bold;
    text-align: center;
}

.gradient-tettwitter {
    background: linear-gradient(45deg, #ffffff, #8a8787); /* Blue gradient */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    font-weight: bold; /* Make text bold */
    text-fill-color: transparent;
  }

  .gradient-tettwitch {
    background: linear-gradient(45deg, #af44f7, #d580ff); /* Purple gradient */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    font-weight: bold; /* Make text bold */
    text-fill-color: transparent;
  }

  .gradient-tetdiscord {
    background: linear-gradient(45deg, #2d73f3, #8c00ff5b); /* Blue gradient */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    font-weight: bold; /* Make text bold */
    text-fill-color: transparent;
  }
  

  .bonus-section {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.bonus-content {
  background-color: rgba(0, 0, 0, 0.85); /* Slight transparency */
  border-radius: 15px;
  padding: 30px;
  max-width: 500px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.bonus-content h3 {
  color: #af44f7; /* Purple gradient color */
}

.bonus-content p {
  color: #ddd;
}

.bonus-button {
  background-color: #af44f7;
  color: #fff;
  border: none;
  padding: 10px 25px;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.bonus-button:hover {
  background-color: #d580ff;
  transform: scale(1.05);
}

.image-overlay {
    position: absolute;
    top: 350px;
    left: 0;
    width: 550px;
    height: 100vh;
    background-image: url('../public/assets/banners.png');
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 5;
    animation: rover 6s ease-in-out infinite;
}

@keyframes rover {
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-10px);
    }
    100% {
        transform: translateY(0);
    }
}

.image-overlayse {
    position: absolute;
    top: 350px;
    left: 1340px;
    width: 570px;
    height: 100vh;
    background-image: url('../public/assets/bannered.png');
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 5;
    animation: rovered 6s ease-in-out infinite;
}

@keyframes rovered {
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-10px);
    }
    100% {
        transform: translateY(0);
    }
}

/* Large Screens */
@media (max-width: 1440px) {
    .image-overlay {
    display: none;
    }
    .image-overlayse {
        display: none;
    }
}

/* Medium Screens */
@media (max-width: 1024px) {
    .image-overlay {
        display: none;
    }
    .image-overlayse {
        display: none;
    }
}

/* Small Screens */
@media (max-width: 768px) {
    .image-overlay {
        display: none;
    }
    .image-overlayse {
        display: none;
    }
}

/* Extra Small Screens */
@media (max-width: 425px) {
    .image-overlay {
display: none;
    }
    .image-overlayse {
        display: none;
    }
}
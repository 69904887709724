/* BonusForum.css */

/* Animate Gradient Background */
body {
  margin: 0;
  padding: 0;
  background: linear-gradient(-45deg, #ffd700, #000000, #ffd700, #000000);
  background-size: 400% 400%;
  animation: gradientAnimation 15s ease infinite;
}

@keyframes gradientAnimation {
  0% {
      background-position: 0% 50%;
  }
  50% {
      background-position: 100% 50%;
  }
  100% {
      background-position: 0% 50%;
  }
}


.bonus-forum {
  max-width: 500px;
  margin: 50px auto;
  padding: 30px;
  background: linear-gradient(135deg, #1a1a1a 0%, #333333 100%);
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(255, 215, 0, 0.5);
  text-align: center;
  border: 2px solid;
  border-image: linear-gradient(45deg, gold, orange) 1;
}

.bonus-forum h2 {
  font-size: 2rem;
  margin-bottom: 1.5rem;
  color: gold;
  text-shadow: 0 0 10px gold;
}

.bonus-forum form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.bonus-forum label {
  font-weight: 500;
  margin-bottom: 0.5rem;
  color: #f1c40f;
  width: 100%;
  text-align: left;
}

.bonus-forum input,
.bonus-forum select {
  padding: 12px;
  font-size: 1rem;
  border: 1px solid #555;
  border-radius: 6px;
  margin-bottom: 1.5rem;
  width: 100%;
  background-color: #222;
  color: #fff;
}

.bonus-forum input::placeholder {
  color: #bbb;
}

.bonus-forum button {
  padding: 12px 20px;
  font-size: 1.2rem;
  color: #000;
  background: linear-gradient(to right, gold, orange);
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.3s ease;
}

.bonus-forum button:hover {
  background: orange;
  transform: scale(1.05);
}

.message {
  margin-top: 1rem;
  font-size: 1rem;
  color: #ff4444;
}

.recaptcha-container {
  margin: 20px 0;
}

@media (max-width: 600px) {
  .bonus-forum {
      margin: 20px;
      padding: 20px;
  }

  .bonus-forum h2 {
      font-size: 1.5rem;
  }

  .bonus-forum button {
      font-size: 1rem;
      padding: 10px 15px;
  }
}

/* BonusForum.css */
.message {
  font-size: 1rem;
  margin-top: 1rem;
}

.message.success {
  color: #28a745; /* Bright green color */
  font-weight: bold;
}

.message.error {
  color: #dc3545; /* Bright red color for errors */
  font-weight: bold;
}
